import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Cont404 } from 'common/containers/404';
import { DocumentUpload } from 'common/containers/document-upload';
import { useStoreConfig } from 'common/providers/config';
import { useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import { DocumentCategory } from 'entities/documents/documents-v2';

const getTitle = (category: DocumentCategory = DocumentCategory.Identity) =>
  ({
    [DocumentCategory.Identity]: 'Government-issued ID',
    [DocumentCategory.Medical]: 'Medical Documents',
    [DocumentCategory.Additional]: 'Additional Documents',
  })[category];

const getDescription = (category: DocumentCategory = DocumentCategory.Identity) =>
  ({
    [DocumentCategory.Identity]:
      'To verify that you’re legally able to purchase cannabis, we need a copy of your state or federally issued ID',
    [DocumentCategory.Medical]:
      'To verify that you’re eligible for medicinal cannabis, we need a copy of your medical card or recommendation',
    [DocumentCategory.Additional]:
      'Uploading documents like veteran, first responder, or student IDs may give you access to related discount programs',
  })[category];

const CheckoutAddDocumentByCategoryPage: ApplicationPage = () => {
  const { isOnlineOrderingDisabled } = useStoreConfig();
  const { category } = useRouterQuery();

  if (isOnlineOrderingDisabled) {
    return <Cont404 />;
  }

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{
        title: getTitle(category as DocumentCategory),
        description: getDescription(category as DocumentCategory),
        showBreadcrumbs: false,
        defaultBackRoute: Routes.CheckoutDocuments,
      }}
    >
      <DocumentUpload category={category as DocumentCategory} />
    </Page>
  );
};

CheckoutAddDocumentByCategoryPage.getMeta = ({ query: { category } }) => {
  return {
    title: getTitle(category as DocumentCategory),
    description: getDescription(category as DocumentCategory),
  };
};

CheckoutAddDocumentByCategoryPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
  subheader: null,
});

export default CheckoutAddDocumentByCategoryPage;
